import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TeamMembers = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('http://atabackend.quindltechnologies.com:3003/teammanagement/get');
        setTeamMembers(response.data);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to fetch team members');
        setIsLoading(false);
        console.error('Error fetching team members:', err);
      }
    };

    fetchTeamMembers();
  }, []);

  const handleImageError = (e) => {
    e.target.style.display = 'none'; // Hide image if it fails to load
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-blue-500"></div>
        <span className="ml-2">Loading team members...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center py-4">
        <p>{error}</p>
        <button 
          onClick={() => window.location.reload()} 
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-12 py-8">
      <h2 className="text-2xl font-bold mb-6 text-center">Our Team</h2>
      {teamMembers.length === 0 ? (
        <p className="text-center text-gray-500">No team members found</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {teamMembers.map((member) => (
            <div
              key={member._id}
              className="bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:scale-105"
            >
              {member.image ? (
                <div className="w-full h-64 flex items-center justify-center bg-gray-100">
                  <img
                    src={`http://atabackend.quindltechnologies.com:3003/uploads/${member.image}`}
                    alt={member.name}
                    onError={handleImageError}
                    className="w-full h-full object-cover object-center"
                  />
                </div>
              ) : (
                <div className="w-full h-64 bg-gray-200 flex items-center justify-center">
                  <span className="text-gray-500">No Image</span>
                </div>
              )}
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-2">{member.name}</h3>
                <p className="text-gray-600 mb-2">{member.designation}</p>
                <p className="text-gray-500 line-clamp-3">{member.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TeamMembers;