import React from 'react';

const MissionVisionSection = () => {
  return (
    <div className="w-full px-4 py-8 md:px-8 lg:px-16 bg-gray-50">
      <div className="max-w-7xl mx-auto">
        {/* Mission and Vision Container */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
          {/* Mission Image */}
          <div className="flex items-center justify-center transition-transform duration-300 hover:scale-105">
            <div className="w-full max-w-1md aspect-square">
              <img
                src="/assets/Our Mission-1.svg"
                alt="Mission"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
          
          {/* Vision Image */}
          <div className="flex items-center justify-center transition-transform duration-300 hover:scale-105">
            <div className="w-full max-w-1.5md aspect-square">
              <img
                src="/assets/Our Vision-1.svg"
                alt="Vision"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        </div>

        {/* Join Community Button */}
        <div className="flex justify-center mt-8 md:mt-12">
          <button
            className="w-full md:w-auto px-6 py-3 bg-white border-2 text-orange-500 border-[#E16811] 
            hover:bg-[#E16811] hover:text-white transition-colors duration-300 
            text-base md:text-lg font-medium rounded-md 
            focus:outline-none focus:ring-2 focus:ring-[#E16811] focus:ring-opacity-50"
            onClick={() => window.location.href = '/membership'}
          >
            Join Our Community
          </button>
          
        </div>
      </div>
    </div>
  );
};

export default MissionVisionSection;