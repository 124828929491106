import React, { useState } from "react";

const PrivacyPolicy = () => {
  const [accepted, setAccepted] = useState(false);

  const handleCheckboxChange = () => {
    setAccepted(!accepted);
  };

  const handleSubmit = () => {
    if (accepted) {
      alert("Thank you for accepting the privacy policy!");
    } else {
      alert("Please accept the privacy policy to proceed.");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-lg">
        <h1 className="text-2xl font-bold mb-4 text-center">Privacy Policy</h1>
        <div className="text-gray-700 mb-6">
          <p>
            We are committed to protecting your personal information and your right to
            privacy. This policy explains how we collect, use, and safeguard your
            information when you use our application.
          </p>
          <ul className="list-disc ml-6 mt-4">
            <li>We collect information you provide directly to us.</li>
            <li>Your data is stored securely and used only for stated purposes.</li>
            <li>We do not share your data with third parties without your consent.</li>
            <li>
              You have the right to access, modify, or delete your personal information.
            </li>
          </ul>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="acceptPrivacy"
            className="w-5 h-5 text-blue-600 border-gray-300 rounded"
            checked={accepted}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="acceptPrivacy" className="ml-3 text-gray-700">
            I agree to the privacy policy.
          </label>
        </div>
        <button
          className={`mt-6 w-full py-2 px-4 rounded-md text-white ${
            accepted ? "bg-blue-500 hover:bg-blue-600" : "bg-gray-400"
          }`}
          onClick={handleSubmit}
          disabled={!accepted}
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
