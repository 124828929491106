import React, { useState, useRef, useEffect } from 'react';
import { Menu, X, ChevronDown, LogOut, LogIn } from 'lucide-react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dropdownRef = useRef(null);

  const aboutDropdownItems = [
    { title: 'About Us', path: '/about' },
    { title: 'Our Team', path: '/team' },
  ];
  
  const menuItems = [
    { title: 'Home', path: '/' },
    {
      title: 'About',
      path: '/about',
      hasDropdown: true,
      dropdownItems: aboutDropdownItems,
    },
    { title: 'Membership', path: '/membership' },
    { title: 'Events', path: '/events' },
    { title: 'Gallery', path: '/gallery' },
    { title: 'Blog', path: '/blog' },
    { title: 'Contact', path: '/contact' },
  ];

  useEffect(() => {
    const user = localStorage.getItem('user');
    setIsLoggedIn(!!user);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('user');
    setIsLoggedIn(false);
    window.location.href = '/';
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsAboutDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <header className="bg-white relative shadow-md">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-24 items-center">
          {/* Logo */}
          <div className="flex-shrink-0">
            <img src="/assets/header1.svg" alt="Logo" className="h-20 w-20" />
          </div>

          {/* Desktop Menu */}
          <div className="hidden lg:flex items-center space-x-6">
            {menuItems.map((item) => (
              <div key={item.title} className="relative group" ref={item.hasDropdown ? dropdownRef : null}>
                <a
                  href={item.path}
                  className="text-gray-700 hover:text-orange-500 px-3 py-2 text-sm font-medium inline-flex items-center"
                >
                  {item.title}
                  {item.hasDropdown && (
                    <ChevronDown className="ml-1 h-4 w-4 transition-transform duration-200 group-hover:rotate-180" />
                  )}
                </a>
                {/* Dropdown */}
                {item.hasDropdown && (
                  <div className="absolute z-10 w-48 py-2 mt-2 bg-white rounded-md shadow-lg opacity-0 -translate-y-2 invisible group-hover:opacity-100 group-hover:translate-y-0 group-hover:visible transition-all duration-200">
                    {item.dropdownItems.map((dropdownItem) => (
                      <a
                        key={dropdownItem.title}
                        href={dropdownItem.path}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-orange-50 hover:text-orange-500"
                      >
                        {dropdownItem.title}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ))}
            
            {/* Login/Logout Button */}
            {isLoggedIn ? (
              <button
                onClick={handleLogout}
                className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-orange-500 hover:bg-orange-600 transition-colors duration-200"
                aria-label="Logout"
              >
                <LogOut className="h-5 w-5 text-white" />
              </button>
            ) : (
              <a
                href="/login"
                className="inline-flex items-center justify-center px-6 py-2 rounded-full bg-orange-500 hover:bg-orange-600 transition-colors duration-200"
              >
                {/* <LogIn className="h-5 w-5 text-white mr-2" /> */}
                <span className="text-white text-sm font-medium">Login</span>
              </a>
            )}
          </div>

          {/* Mobile Menu Button */}
          <div className="lg:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-700 hover:text-orange-500 focus:outline-none transition-colors duration-200"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`lg:hidden transition-all duration-300 ease-in-out ${
            isMenuOpen ? 'max-h-screen opacity-100 visible' : 'max-h-0 opacity-0 invisible'
          } overflow-hidden bg-white absolute left-0 right-0 z-50`}
        >
          <div className="py-4 px-4 space-y-1 border-t border-gray-100">
            {menuItems.map((item) => (
              <div key={item.title} className="relative">
                <a
                  href={item.path}
                  className="block py-2 text-base font-medium text-gray-700 hover:text-orange-500"
                >
                  <span className="flex items-center justify-between">
                    {item.title}
                    {item.hasDropdown && <ChevronDown className="h-4 w-4" />}
                  </span>
                </a>
                {item.hasDropdown && (
                  <div className="pl-4 mt-1 space-y-1">
                    {item.dropdownItems.map((dropdownItem) => (
                      <a
                        key={dropdownItem.title}
                        href={dropdownItem.path}
                        className="block py-2 text-sm text-gray-600 hover:text-orange-500"
                      >
                        {dropdownItem.title}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ))}
            
            {/* Mobile Login/Logout */}
            <div className="pt-2">
              {isLoggedIn ? (
                <button
                  onClick={handleLogout}
                  className="flex w-1/4 items-center justify-center py-2 px-4 rounded-full bg-orange-500 hover:bg-orange-600 text-white transition-colors duration-200"
                >
                  <LogOut className="h-5 w-5 mr-2" />
                  <span className="text-sm font-medium">Logout</span>
                </button>
              ) : (
                <a
                  href="/login"
                  className="flex w-full items-center justify-center py-2 px-4 rounded-full bg-orange-500 hover:bg-orange-600 text-white transition-colors duration-200"
                >
                  
                    <span className="text-sm font-medium">Login</span>
                    <LogIn className="h-5 w-5 mr-2" />
                </a>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;