import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle } from 'lucide-react';

const SpouseChildrenDetailsForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [membershipType, setMembershipType] = useState('');
  const [userId, setUserId] = useState('');
  const [showStudentAlert, setShowStudentAlert] = useState(false);
  const [error, setError] = useState('');
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);

  const [spouseDetails, setSpouseDetails] = useState({
    name: '',
    age: ''
  });

  const [childrenDetails, setChildrenDetails] = useState([
    { name: '', age: '' },
    { name: '',  age: '' },
    { name: '', age: '' }
  ]);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    
    if (location.state) {
      setMembershipType(location.state.membershipType || '');
      setUserId(location.state.userId || storedUserId || '');
    } else if (storedUserId) {
      setUserId(storedUserId);
    } else {
      // Redirect if no userId is found
      navigate('/billing');
    }
  }, [location, navigate]);

  const validateName = (name) => {
    return name.trim().length >= 2 && /^[A-Za-z\s]+$/.test(name);
  };

  const validateAge = (age, min = 0, max = 120) => {
    const parsedAge = parseInt(age);
    return !isNaN(parsedAge) && parsedAge >= min && parsedAge <= max;
  };

  const handleSpouseChange = (e) => {
    const { name, value } = e.target;
    setSpouseDetails(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const handleChildChange = (index, e) => {
    const { name, value } = e.target;
    const updatedChildren = [...childrenDetails];
    updatedChildren[index] = {
      ...updatedChildren[index],
      [name]: value
    };
    setChildrenDetails(updatedChildren);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setShowStudentAlert(false);

    // Validate spouse details if provided
    if (spouseDetails.name || spouseDetails.age) {
      if (!validateName(spouseDetails.name)) {
        setError('Please enter a valid spouse name (at least 2 characters, letters only).');
        return;
      }
      if (!validateAge(spouseDetails.age)) {
        setError('Please enter a valid spouse age (0-120).');
        return;
      }
    }

    // Validate children details
    const validChildren = childrenDetails.filter(child => 
      (child.name || child.age) && validateName(child.name) && validateAge(child.age, 0, 18)
    );

    // Family membership specific validation
    if (['Silver-Family', 'Gold-Family'].includes(membershipType)) {
      const childrenUnder18 = validChildren.length > 0;

      if (!childrenUnder18) {
        setShowStudentAlert(true);
        return;
      }
    }

    // Prepare submission data
    const submissionData = {
      userId: userId,
      membershipType: membershipType,
      spouse: spouseDetails && spouseDetails.name ? spouseDetails : null, 
      children: validChildren
    };

    try {
      const response = await fetch('http://atabackend.quindltechnologies.com:3003/membership/add-dependents', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      });

      const responseData = await response.json();
      
      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to submit dependents details');
      }

      // Clear userId from localStorage after successful submission
      localStorage.removeItem('userId');
      
      // Show Thank You Popup
      setShowThankYouPopup(true);

      // Auto-redirect after 3 seconds
      setTimeout(() => {
        navigate('/', {
          state: {
            message: 'Membership application submitted successfully!'
          }
        });
      }, 3000);

    } catch (error) {
      console.error('Submission Error:', error);
      setError(error.message || 'An unexpected error occurred');
    }
  };

  // Thank You Popup Component
  const ThankYouPopup = () => (
    <motion.div 
      initial={{ opacity: 0, scale: 0.7 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.7 }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <motion.div 
        className="bg-white p-6 sm:p-8 rounded-lg shadow-2xl text-center max-w-sm sm:max-w-md w-[90%]"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <CheckCircle 
          className="mx-auto mb-4 text-green-500" 
          size={48} 
          strokeWidth={1.5} 
        />
        <h2 className="text-xl sm:text-2xl font-bold mb-4 text-gray-800">
          Thank You!
        </h2>
        <p className="text-sm sm:text-base text-gray-600 mb-6">
          Your family membership details have been successfully submitted.
        </p>
        <p className="text-xs sm:text-sm text-gray-500">
          Redirecting to home page...
        </p>
      </motion.div>
    </motion.div>
  );

  return (
    <div className="bg-gradient-to-br from-purple-100 via-pink-50 to-blue-50 min-h-screen p-4 sm:p-12">
      {/* Thank You Popup */}
      <AnimatePresence>
        {showThankYouPopup && <ThankYouPopup />}
      </AnimatePresence>

      <form 
        onSubmit={handleSubmit} 
        className="w-full max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-6 sm:p-8"
      >
        <h2 className="text-xl sm:text-2xl font-bold mb-6 text-gray-800 text-center">
          {membershipType} Membership - Family Details
        </h2>

        {/* Error Alert */}
        <AnimatePresence>
          {error && (
            <motion.div 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 text-sm sm:text-base"
              role="alert"
            >
              {error}
            </motion.div>
          )}
        </AnimatePresence>

        {/* Student Alert Animation */}
        <AnimatePresence>
          {showStudentAlert && (
            <motion.div 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4 text-sm sm:text-base"
              role="alert"
            >
              <p className="font-bold">Student Membership Requirement</p>
              <p>Please include at least one child under 18 for Silver/Gold Family membership.</p>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Spouse Section */}
        <div className="mb-8">
          <h3 className="text-lg sm:text-xl font-semibold mb-4 text-gray-700">
            Spouse Details (Optional)
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <input
              type="text"
              name="name"
              placeholder="Spouse Name"
              value={spouseDetails.name}
              onChange={handleSpouseChange}
              className="w-full p-2 border border-gray-300 rounded-md text-sm sm:text-base"
            />
          
            <input
              type="number"
              name="age"
              placeholder="Age"
              value={spouseDetails.age}
              onChange={handleSpouseChange}
              className="w-full p-2 border border-gray-300 rounded-md text-sm sm:text-base"
            />
          </div>
        </div>

        {/* Children Section */}
        <div>
          <h3 className="text-lg sm:text-xl font-semibold mb-4 text-gray-700">
            Children Details
          </h3>
          {childrenDetails.map((child, index) => (
            <div 
              key={index} 
              className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4"
            >
              <input
                type="text"
                name="name"
                placeholder={`Child ${index + 1} Name`}
                value={child.name}
                onChange={(e) => handleChildChange(index, e)}
                className="w-full p-2 border border-gray-300 rounded-md text-sm sm:text-base"
              />

              <input
                type="number"
                name="age"
                placeholder="Age"
                value={child.age}
                onChange={(e) => handleChildChange(index, e)}
                className="w-full p-2 border border-gray-300 rounded-md text-sm sm:text-base"
              />
            </div>
          ))}
        </div>

        <div className="text-center mt-6">
          <button 
            type="submit" 
            className="w-full sm:w-auto bg-orange-500 text-white py-3 px-8 rounded-md hover:bg-orange-600 transition duration-200 text-sm sm:text-base"
          >
            Submit 
          </button>
        </div>
      </form>
    </div>
  );
};

export default SpouseChildrenDetailsForm;