import React, { useState, useEffect } from 'react';
import { AtSign, Lock, Eye, EyeOff, CheckCircle, AlertCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

// Utility functions for handling authentication
const AUTH_TIMEOUT = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const setUserWithExpiry = (userData) => {
  const now = new Date().getTime();
  const item = {
    value: userData,
    expiry: now + AUTH_TIMEOUT,
  };
  localStorage.setItem('user', JSON.stringify(item));
};

const checkAndClearExpiredAuth = () => {
  const userStr = localStorage.getItem('user');
  if (!userStr) return null;

  const item = JSON.parse(userStr);
  const now = new Date().getTime();

  if (now > item.expiry) {
    localStorage.removeItem('user');
    window.location.href = '/login';
    return null;
  }
  return item.value;
};

// Logout function
const logout = () => {
  localStorage.removeItem('user');
  window.location.href = '/login';
};

const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState('');
  const [notification, setNotification] = useState({
    show: false,
    success: false,
    message: ''
  });
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loginStatus, setLoginStatus] = useState({
    show: false,
    success: false,
    message: ''
  });

  // Check for expired authentication on component mount and periodically
  useEffect(() => {
    // Check on mount
    checkAndClearExpiredAuth();

    // Set up periodic checks
    const interval = setInterval(() => {
      checkAndClearExpiredAuth();
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    if (!formData.password.trim()) {
      newErrors.password = 'Password is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoginError('');
  
    if (validateForm()) {
      fetch('http://atabackend.quindltechnologies.com:3003/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401 || response.status === 403) {
              throw new Error('Invalid email or password');
            }
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          // Store user information with expiry
          setUserWithExpiry({
            id: data.userId || '',
            name: data.name || '',
            email: formData.email,
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            phone: data.phone || '',
            token: data.token || ''
          });

          setLoginStatus({
            show: true,
            success: true,
            message: 'Login successful!'
          });

          setTimeout(() => {
            window.location.href = '/';
          }, 2000);
        })
        .catch((error) => {
          console.error('Error during login:', error.message);
          
          setLoginStatus({
            show: true,
            success: false,
            message: error.message || 'Login failed. Please try again.'
          });

          setTimeout(() => {
            setLoginStatus(prev => ({ ...prev, show: false }));
          }, 3000);
        });
    }
  };
  const handleGoogleSignIn = () => {
    // Google Sign-In logic
    console.log('Google Sign-In initiated');
  };

  const handleResetLink = (e) => {
    e.preventDefault();
  
    // Reset errors and notification state
    setErrors({});
    setNotification({ show: false, success: false, message: "" });

    if (!formData.email.trim()) {
      setErrors({ email: "Email is required" });
      return;
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrors({ email: "Invalid email format" });
      return;
    }
    console.log("Send reset link to:", formData.email);
    // Simulate an API request to send the reset link
    fetch("http://atabackend.quindltechnologies.com:3003/auth/send-reset-email", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: formData.email }),
    })  
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setNotification({
            show: true,
            success: true,
            message: "Reset link sent successfully. Check your email.",
          });
        } else {
          throw new Error(data.message || "Failed to send reset link.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setNotification({
          show: true,
          success: false,
          message: error.message || "Failed to send reset link.",
        });
      });
  };
  
  return (
    <div className="min-h-screen flex items-center justify-center px-4 py-8 relative">
      {/* Notification Animation */}
      <AnimatePresence>
        {loginStatus.show && (
          <motion.div 
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className={`fixed top-4 z-50 px-6 py-4 rounded-lg shadow-lg text-center w-72 mx-auto left-0 right-0 
              ${loginStatus.success 
                ? 'bg-green-100 text-green-800' 
                : 'bg-red-100 text-red-800'
              }`}
          >
            {loginStatus.success ? (
              <div className="flex items-center justify-center">
                <CheckCircle className="mr-2 h-6 w-6" />
                {loginStatus.message}
              </div>
            ) : (
              <motion.div 
                className="flex items-center justify-center"
                animate={{ 
                  x: [0, -10, 10, -10, 10, 0],
                  transition: { duration: 0.5 }
                }}
              >
                <AlertCircle className="mr-2 h-6 w-6" />
                {loginStatus.message}
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {notification.show && (
          <motion.div 
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className={`fixed top-4 z-50 px-6 py-4 rounded-lg shadow-lg text-center w-72 mx-auto left-0 right-0 
              ${notification.success 
                ? 'bg-green-100 text-green-800' 
                : 'bg-red-100 text-red-800'
              }`}
          >
            {notification.success ? (
              <div className="flex items-center justify-center">
                <CheckCircle className="mr-2 h-6 w-6" />
                {notification.message}
              </div>
            ) : (
              <motion.div 
                className="flex items-center justify-center"
                animate={{ 
                  x: [0, -10, 10, -10, 10, 0],
                  transition: { duration: 0.5 }
                }}
              >
                <AlertCircle className="mr-2 h-6 w-6" />
                {notification.message}
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {
        !forgotPassword ? 
        <div className="bg-white shadow-md rounded-lg w-full max-w-md p-8">
          <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
            Welcome Back
          </h2>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full pl-10 pr-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
                <AtSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              </div>
              {errors.email && (
                <motion.p 
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-red-500 text-xs mt-1"
                >
                  {errors.email}
                </motion.p>
              )}
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={handleChange}
                  className="w-full pl-10 pr-10 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
                <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
              {errors.password && (
                <motion.p 
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-red-500 text-xs mt-1"
                >
                  {errors.password}
                </motion.p>
              )}
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <div onClick={()=>setForgotPassword(true)} className="font-medium text-orange-600 hover:text-orange-500">
                  Forgot password?
                </div>
              </div>
            </div>

            <div>
              <motion.button
                type="submit"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                Sign In
              </motion.button>
            </div>
          </form>

          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600">
              Don't have an account? 
              <a 
                href="/register"
                className="font-medium text-orange-600 hover:text-orange-500 ml-1"
              >
                Register
              </a>
            </p>
          </div>
        </div>
        :
        <div className="bg-white shadow-md rounded-lg w-full max-w-md p-8">
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm pb-2 font-medium text-gray-700">
                Enter Your Email
              </label>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full pl-10 pr-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
                <AtSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              </div>
              {errors.email && (
                <motion.p 
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-red-500 text-xs mt-1"
                >
                  {errors.email}
                </motion.p>
              )}
            </div>
            
            <div
              onClick={handleResetLink}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                Send Reset Link
            </div>
          </div>

          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600">
              <a 
                href="/login"
                className="font-medium text-orange-600 hover:text-orange-500 ml-1"
              >
                Back to login
              </a>
            </p>
          </div>
        </div>
      }
    </div>
  );
};

export default LoginPage;