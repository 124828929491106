import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { CalendarDays, ArrowLeft, Search, X, Image as ImageIcon } from 'lucide-react';

// BlogCard Component
const BlogCard = ({ post, imageBaseUrl }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Link
      to={`/blog/${post._id}`}
      className="group block bg-white rounded-2xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:scale-[1.02]"
    >
      <div className="h-48 overflow-hidden relative bg-white  flex items-center justify-center">
        {!imageError ? (
          <div className="w-full h-full">
            <img
              src={`${imageBaseUrl}/${post.image}`}
              alt={post.name || 'Blog Post'}
              className="w-full h-full object-cover transition-transform group-hover:scale-110"
              onError={handleImageError}
              loading="lazy"
            />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center text-gray-400 w-full h-full">
            <ImageIcon className="w-16 h-16 mb-2 opacity-50" />
            <p className="text-sm font-medium">No Image Available</p>
          </div>
        )}
      </div>
      <div className="p-5">
        <h3 className="font-bold text-xl text-gray-900 mb-3 line-clamp-2 group-hover:text-orange-600 transition-colors">
          {post.name}
        </h3>
        <div className="flex items-center text-sm text-gray-500">
          <CalendarDays className="w-5 h-5 mr-2 text-orange-400" />
          <span className="font-medium">{new Date(post.date).toLocaleDateString()}</span>
        </div>
      </div>
    </Link>
  );
};

// BlogList Component
const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFocused, setSearchFocused] = useState(false);

  const IMAGE_BASE_URL = 'http://atabackend.quindltechnologies.com:3003/uploads';

  useEffect(() => {
    if (loading) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [loading]);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch('http://atabackend.quindltechnologies.com:3003/blog/get');
        const data = await response.json();
        
        // Sort posts by date (newest first)
        const sortedPosts = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setBlogPosts(sortedPosts);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  const filteredPosts = blogPosts.filter(post =>
    searchTerm === '' ||
    (post.name && post.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (post.desc && post.desc.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  if (loading) return (
    <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-90 z-50">
      <div className="animate-pulse text-orange-500 font-semibold">Loading blogs...</div>
    </div>
  );

  if (error) return (
    <div className="container mx-auto px-4 py-12 text-center">
      <div className="bg-red-100 border border-red-400 text-red-700 px-6 py-4 rounded-lg">
        Error loading blogs
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-12">
      <div className="max-w-6xl mx-auto relative">
        {/* Responsive Search Section */}
        <div className="mb-10 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          <h1 className="text-4xl font-bold text-gray-900">Our Blog</h1>
          <div className="w-full sm:w-auto">
            <div className={`flex items-center bg-white border-2 rounded-full px-4 py-2 transition-all duration-300 ${searchFocused ? 'border-orange-500 shadow-lg ring-2 ring-orange-100' : 'border-gray-200 hover:border-gray-300'}`}>
              <Search className={`mr-3 ${searchFocused ? 'text-orange-500' : 'text-gray-400'}`} />
              <input
                type="text"
                placeholder="Search blogs..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setSearchFocused(false)}
                className="flex-grow bg-transparent outline-none text-gray-700 placeholder-gray-400 text-base w-full sm:w-64"
              />
              {searchTerm && (
                <button onClick={() => setSearchTerm('')} className="text-gray-400 hover:text-gray-600">
                  <X className="w-5 h-5" />
                </button>
              )}
            </div>
          </div>
        </div>

        {searchTerm && (
          <div className="text-sm text-gray-500 mb-6 text-center sm:text-left">
            {filteredPosts.length} blog{filteredPosts.length !== 1 ? 's' : ''} found
          </div>
        )}

        {filteredPosts.length === 0 && (
          <div className="text-center py-16 bg-gray-50 rounded-xl">
            <Search className="mx-auto w-16 h-16 text-gray-300 mb-4" />
            <p className="text-xl text-gray-500">No blogs found matching "{searchTerm}"</p>
          </div>
        )}

        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredPosts.map((post) => (
            <BlogCard key={post._id} post={post} imageBaseUrl={IMAGE_BASE_URL} />
          ))}
        </div>
      </div>
    </div>
  );
};

// BlogPost Component
const BlogPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    if (loading) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [loading]);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const allBlogsResponse = await fetch('http://atabackend.quindltechnologies.com:3003/blog/get');
        const allBlogs = await allBlogsResponse.json();

        const specificPost = allBlogs.find(blog => blog._id === id);
        if (!specificPost) throw new Error('Blog post not found');

        setPost(specificPost);

        // Get current date and last month's date
        const currentDate = new Date();
        const lastMonth = new Date();
        lastMonth.setMonth(currentDate.getMonth() - 1);

        // Filter and sort related blogs by date
        const recentRelatedBlogs = allBlogs
          .filter(blog => {
            const blogDate = new Date(blog.date);
            return (
              blog._id !== id && 
              (
                (blogDate.getMonth() === currentDate.getMonth() && blogDate.getFullYear() === currentDate.getFullYear()) ||
                (blogDate.getMonth() === lastMonth.getMonth() && blogDate.getFullYear() === lastMonth.getFullYear())
              )
            );
          })
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 3);

        setRelatedBlogs(recentRelatedBlogs);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [id]);

  if (loading) return (
    <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-90 z-50">
      <div className="animate-pulse text-orange-500 font-semibold">Loading blog post...</div>
    </div>
  );

  if (error) return (
    <div className="container p-12 text-center">
      <div className="bg-red-100 border border-red-400 text-red-700 px-6 py-4 rounded-lg">
        Error loading blog post
      </div>
    </div>
  );

  if (!post) return (
    <div className="container p-12 text-center text-gray-500">
      Blog post not found
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-16">
      <div className="max-w-4xl mx-auto">
        <div className="mb-10">
          <button 
            onClick={() => navigate('/blog')} 
            className="flex items-center text-gray-700 hover:text-orange-600 transition-colors group"
          >
            <ArrowLeft className="mr-2 group-hover:-translate-x-1 transition-transform" />
            Back to Blogs
          </button>
        </div>

        <article>
          <h1 className="text-4xl sm:text-5xl font-bold mb-6 text-gray-900 leading-tight">
            {post.name}
          </h1>
          <div className="flex items-center text-sm text-gray-600 mb-8">
            <CalendarDays className="w-5 h-5 mr-3 text-orange-500" />
            <span className="font-medium">{new Date(post.date).toLocaleDateString()}</span>
          </div>

          <div className="mb-10 rounded-2xl overflow-hidden shadow-xl transform hover:scale-[1.01] transition-transform">
            <img 
              src={`${process.env.REACT_APP_BASE_URL}/uploads/${post.image}`}
              alt={post.name || 'Blog Post'}
              className="w-full h-auto object-cover" 
              loading="lazy"
            />
          </div>

          <div className="prose prose-lg max-w-none text-gray-800 leading-relaxed space-y-6">
            <p>{post.desc}</p>
          </div>
        </article>

        {relatedBlogs.length > 0 && (
          <div className="mt-16 border-t pt-12">
            <h2 className="text-3xl font-bold mb-10 text-gray-900">Recent Similar Blogs</h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {relatedBlogs.map((relatedPost) => (
                <BlogCard 
                  key={relatedPost._id} 
                  post={relatedPost} 
                  imageBaseUrl={`${process.env.REACT_APP_BASE_URL}/uploads/${relatedPost.image}`}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { BlogPost, BlogList };