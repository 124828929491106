import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Home from './pages/home/home';
import About from './pages/about/about';
import Contact from './pages/Contact/contact';
import { BlogList, BlogPost } from './pages/Blogs/blogs';
import Gallery from './pages/Gallery/gallery';
import Membership from './pages/Membership/membership';
import Form from './pages/Membership/form';
import Raise from './pages/service/raiseticket';
import Team from './pages/about/team';
import Events from './pages/events/events';
import Register from './components/register';
import Login from './components/login';
import Conform from './pages/Membership/conformation';
import Privacy from "./pages/privacy/privacy";
import Terms from "./pages/terms/terms";
import Tamil from "./pages/tamilschool/tamilschool";
import ResetPassword from './pages/ForgetPassword/resetpassword';

function App() {
  return (
    <BrowserRouter>
      <div className="flex flex-col">
        <Header />
        <main className="flex-grow ">
          <div className="">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/membership" element={<Membership />} />
              <Route path="/forms" element={<Form />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/blog/:id" element={<BlogPost />} /> {/* Fixed */}
              <Route path="/blog" element={<BlogList />} /> {/* Fixed */}
              <Route path="/contact" element={<Contact />} />
              <Route path="/raiseticket" element={<Raise />} />
              <Route path="/team" element={<Team />} />
              <Route path="/events" element={<Events />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/confirmation" element={<Conform />} />
              <Route path="/privacy&policy" element={<Privacy/>} />
              <Route path="/terms&conditions" element={<Terms/>} />
              <Route path="/tamilschools" element={<Tamil />} />
              <Route path='/reset-password/:token' element={<ResetPassword />} />
            </Routes>
          </div>
        </main>
        <Footer className="mt-auto" />
      </div>
    </BrowserRouter>
  );
}

export default App;