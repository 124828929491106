import React, { useState, useEffect, useRef } from 'react';
import { ChevronRight, Play } from 'lucide-react';
import Product from './product';
import Mission from './mission';
import Post from './post';
import Event from './event';
import './home.css';
import Vayal from "./vayal.png";
import Sea from "./Sea.png"
import Mountain from './Mountain.png';
import Kaadu from "./Kaadu.png"
import Palai from "./palai.jpg"

const landscapes = [
  { 
    name: "குறிஞ்சி", 
    description: "Explore Mountain Landscapes",
    backgroundImage: Mountain,
    boxImages: ["/assets/kurinji.svg"]
  },
  { 
    name: "முல்லை", 
    backgroundImage: Kaadu,
    boxImages: ["/assets/marutham.svg"]
  },
  { 
    name: "மருதம்", 
    backgroundImage: Vayal,
    boxImages: ["/assets/mulai.svg"]
  },
  { 
    name: "நெய்தல்", 
    backgroundImage: Sea,
    boxImages: ["/assets/neithal.svg"]
  },
  { 
    name: "பாலை", 
    backgroundImage: Palai,
    boxImages: ["/assets/palai.svg"]
  }
];

const LandingPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [visibleBoxes, setVisibleBoxes] = useState([0, 1]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const productSectionRef = useRef(null);

  const advanceSlide = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentSlide((prev) => (prev + 1) % landscapes.length);
      setVisibleBoxes((prev) => {
        const nextIndex = (prev[1] + 1) % landscapes.length;
        return [prev[1], nextIndex];
      });
      setIsTransitioning(false);
    }, 500);
  };

  useEffect(() => {
    const timer = setInterval(advanceSlide, 5000);
    return () => clearInterval(timer);
  }, []);

  const scrollToSection = (elementRef) => {
    elementRef.current?.scrollIntoView({ 
      behavior: 'smooth', 
      block: 'start' 
    });
  };

  return (
    <div className="flex flex-col">
      {/* Hero Section */}
      <div 
        className="relative w-full min-h-[90vh] bg-cover bg-center transition-all duration-500"
        style={{ 
          backgroundImage: `url(${landscapes[currentSlide].backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        {/* Overlay for better text visibility */}
        <div className="absolute inset-0 bg-black/30" />

        <div className="relative z-10 w-full h-full px-4 lg:px-12 flex flex-col">
          {/* Header */}
          <div className="mt-8 lg:mt-12 mb-6 lg:mb-8 text-center">
            <h1 className="text-3xl lg:text-4xl font-semibold text-white mb-8 lg:mb-12">
              {landscapes[currentSlide].name}
            </h1>
          </div>

          {/* Main Content Container */}
          <div className="flex-1 flex flex-col lg:flex-row items-center justify-between">
            {/* Text Content - Adjusted width and spacing */}
            <div className="w-full lg:w-1/2 text-center lg:text-left px-4 lg:px-8">
              <h2 className="text-orange-400 text-2xl lg:text-2xl mb-4 lg:mb-8">
                Welcome to "Adelaide Tamil Association"
              </h2>
              <h3 className="text-2xl lg:text-4xl font-bold text-white mb-4">
                Improve your Communication
              </h3>
              <h3 className="text-2xl lg:text-4xl font-bold text-white mb-6 lg:mb-8">
                with Us
              </h3>
              <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                <button 
                  onClick={() => scrollToSection(productSectionRef)}
                  className="bg-orange-500 hover:bg-orange-600 text-base lg:text-lg text-white px-6 py-3 rounded-lg flex items-center gap-2 justify-center transition-colors"
                >
                  Get Started
                  <ChevronRight className="w-4 h-4 lg:w-5 lg:h-5" />
                </button>
                <button className="text-orange-500 hover:text-orange-400 px-6 py-3 text-base lg:text-lg rounded-lg flex items-center gap-2 justify-center transition-colors">
                  <Play className="w-6 h-6" />
                  Play Video
                </button>
              </div>
            </div>

            {/* Image Boxes - Adjusted sizes and positioning */}
            <div className="hidden lg:flex w-1/2 justify-end items-center gap-6 relative pr-8">
              {visibleBoxes.map((boxIndex, idx) => (
                <div 
                  key={boxIndex}
                  className={`relative ${
                    idx === 1 ? 'w-[220px] h-[300px]' : 'w-[260px] h-[340px]'
                  } transform transition-all duration-500 ${
                    isTransitioning && idx === 0 ? 'opacity-0 translate-x-full' : 'opacity-100 translate-x-0'
                  }`}
                  style={{ 
                    transitionDelay: idx === 1 ? '100ms' : '0ms', 
                    position: 'absolute',
                    right: idx === 0 ? '240px' : '0'
                  }}
                >
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2 text-white text-lg font-semibold mt-2">
                    {landscapes[boxIndex].name}
                  </div>

                  <div className="absolute inset-0 rounded-[24px] overflow-hidden border-4 border-white">
                    {landscapes[boxIndex].boxImages.map((image, imageIndex) => (
                      <img 
                        key={imageIndex}
                        src={image}
                        alt={`${landscapes[boxIndex].description} - Image ${imageIndex + 1}`}
                        className="absolute inset-0 w-full h-full object-cover transition-opacity duration-500"
                        style={{
                          opacity: imageIndex === 0 ? 1 : 0,
                          zIndex: imageIndex === 0 ? 10 : 1
                        }}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Navigation Dots */}
          <div className="flex justify-center gap-3 mt-24 lg:mt-12 ">
            {landscapes.map((_, index) => (
              <button
                key={index}
                onClick={() => {
                  setIsTransitioning(true);
                  setTimeout(() => {
                    setCurrentSlide(index);
                    setVisibleBoxes([index, (index + 1) % landscapes.length]);
                    setIsTransitioning(false);
                  }, 500);
                }}
                className={`w-3 h-3 rounded-full transition-all duration-300 ${
                  currentSlide === index 
                    ? 'bg-white scale-100' 
                    : 'bg-white/50 scale-75 hover:scale-90 hover:bg-white/70'
                }`}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Content Sections */}
      <div ref={productSectionRef} className="w-full pb-16">
        <Product />
      </div>
      <div className="w-full">
        <Mission/>
      </div>
      <div className="w-full">
        <Post/>
      </div>
      <div className="w-full">
        <Event/>
      </div>
    </div>
  );
};

export default LandingPage;