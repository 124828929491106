import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Calendar, MapPin, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const EventsPage = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState({
    ongoing: [],
    upcoming: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Helper function to categorize events based on end date
  const categorizeEvents = (eventList) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const ongoing = [];
    const upcoming = [];

    eventList.forEach(event => {
      // If end_date exists use it for categorization
      if (event.end_date) {
        const endDate = new Date(event.end_date);
        endDate.setHours(0, 0, 0, 0);
        
        if (today <= endDate) {
          ongoing.push(event);
        }
      } else {
        // If no end_date, treat as an upcoming event
        const startDate = new Date(event.date);
        startDate.setHours(0, 0, 0, 0);
        
        if (startDate > today) {
          upcoming.push(event);
        }
      }
    });

    // Sort ongoing events by end date
    ongoing.sort((a, b) => new Date(a.end_date || a.date) - new Date(b.end_date || b.date));
    // Sort upcoming events by start date
    upcoming.sort((a, b) => new Date(a.date) - new Date(b.date));

    return { ongoing, upcoming };
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('http://atabackend.quindltechnologies.com:3003/events/get');
        
        console.log('Raw Response Data:', response.data);
        const categorizedEvents = categorizeEvents(response.data);

        setEvents(categorizedEvents);
        setIsLoading(false);
      } catch (err) {
        console.error('Detailed Error:', err);
        setError(err.message || 'Failed to fetch events');
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-2xl text-gray-600">Loading events...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-2xl text-red-600">
          Error: {error}
          <p className="text-sm mt-2">Please check your backend connection</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold mb-8 text-gray-800">Events</h1>
        
        {events.ongoing.length > 0 && (
          <div className="mb-12">
            <h2 className="text-2xl font-bold mb-6">Ongoing Events</h2>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {events.ongoing.map((event) => (
                <EventCard 
                  key={event._id} 
                  event={event} 
                  navigate={navigate} 
                />
              ))}
            </div>
          </div>
        )}

        {events.upcoming.length > 0 && (
          <div>
            <h2 className="text-2xl font-bold mb-6">Upcoming Events</h2>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {events.upcoming.map((event) => (
                <EventCard 
                  key={event._id} 
                  event={event} 
                  navigate={navigate} 
                />
              ))}
            </div>
          </div>
        )}

        {events.ongoing.length === 0 && events.upcoming.length === 0 && (
          <div className="text-center py-12">
            <p className="text-xl text-gray-600">No events are currently available.</p>
          </div>
        )}
      </div>
    </div>
  );
};

const EventCard = ({ event, navigate }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatDateRange = (startDate, endDate) => {
    if (!endDate || startDate === endDate) {
      return formatDate(startDate);
    }
    
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  };

  const handleJoinClick = () => {
    if (event.eventLink) {
      window.open(event.eventLink, '_blank');
    } else {
      navigate('/membership');
    }
  };

  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300">
      <div className="h-48 bg-gray-200 flex items-center justify-center">
        <div className="text-gray-500 text-center">
          {event.name || 'Event'}
        </div>
      </div>
      
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-2">{event.name}</h3>
        
        <div className="space-y-2 text-gray-500">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Calendar className="w-4 h-4 mr-2" />
              <span>{formatDateRange(event.date, event.end_date)}</span>
            </div>
          </div>
          
          {event.time && event.time !== 'undefined' && (
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-2" />
              <span>{event.time}</span>
            </div>
          )}

          {event.location && (
            <div className="flex items-center">
              <MapPin className="w-4 h-4 mr-2" />
              <span>{event.location}</span>
            </div>
          )}

          {event.description && (
            <div className="text-sm text-gray-600 mt-2">
              {event.description}
            </div>
          )}
        </div>
        
        <button 
          className="mt-4 bg-orange-500 hover:bg-orange-600 text-white font-medium py-2 px-4 rounded-full" 
          onClick={handleJoinClick}
        >
          Join Now
        </button>
      </div>
    </div>
  );
};

export default EventsPage;