import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      setIsError(true);
      return;
    }
    try {
      const response = await fetch('http://atabackend.quindltechnologies.com:3003/auth/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, password }),
      });
      const result = await response.json();
      if (response.ok) {
        setMessage('Password reset successfully!');
        setIsError(false);
        setTimeout(() => navigate('/login'), 1500); // Navigate after 1.5 seconds
      } else {
        setMessage(result.message || 'Something went wrong.');
        setIsError(true);
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
      setIsError(true);
    }
  };

  return (
    <div className="reset-password w-full max-w-md mx-auto mt-10 bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-semibold mb-4 text-center">Reset Password</h2>
      <input
        type="password"
        placeholder="New Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
      />
      <input
        type="password"
        placeholder="Confirm Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
      />
      <button
        onClick={handleResetPassword}
        className="w-full bg-orange-600 hover:bg-orange-700 text-white py-2 px-4 rounded-md shadow focus:outline-none focus:ring-2 focus:ring-orange-500"
      >
        Submit
      </button>

      {message && (
        <motion.p
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className={`mt-4 text-center text-sm ${
            isError ? 'text-red-500' : 'text-green-500'
          }`}
        >
          {message}
        </motion.p>
      )}
    </div>
  );
};

export default ResetPassword;
