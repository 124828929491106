import React, { useState, useEffect } from 'react';

const GalleryDisplay = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isViewingImages, setIsViewingImages] = useState(false);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const response = await fetch('http://atabackend.quindltechnologies.com:3003/gallery/get');
      if (!response.ok) throw new Error('Failed to fetch events');
      const data = await response.json();
      setEvents(data);
      console.log(data);
      if (data.length > 0) {
        const years = [...new Set(data.map(event =>
          new Date(event.date).getFullYear()
        ))];
        setSelectedYear(Math.min(...years));
      }
    } catch (error) {
      setError('Failed to load events');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsViewingImages(true);
  };

  const handleBackClick = () => {
    setSelectedEvent(null);
    setIsViewingImages(false);
  };

  const eventsByYear = events.reduce((acc, event) => {
    const year = new Date(event.date).getFullYear();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(event);
    return acc;
  }, {});

  if (loading) {
    return <div className="text-center p-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 p-8">{error}</div>;
  }

  if (isViewingImages && selectedEvent) {
    return (
      <div className="p-8">
        <div className="max-w-7xl mx-auto">
          <button
            onClick={handleBackClick}
            className="flex items-center text-blue-500 hover:text-blue-700 mb-6"
          >
            <span className="mr-2">←</span> Back to Gallery
          </button>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {selectedEvent.images?.map((image, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg overflow-hidden"
              >
                <div className="aspect-square bg-gray-100">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/uploads/${image}`}
                    alt={`${selectedEvent.name} - Image ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  const years = Object.keys(eventsByYear).sort((a, b) => a - b);

  return (
    <div className="p-4 md:p-8">
      <h1 className="text-2xl font-bold mb-6">Gallery</h1>
      
      <div className="flex gap-4 md:gap-8">
        {/* Gallery Grid */}
        <div className="flex-grow">
          {selectedYear && eventsByYear[selectedYear] && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
              {eventsByYear[selectedYear].map((event) => (
                <div
                  key={event._id}
                  className="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer hover:shadow-xl transition-shadow"
                  onClick={() => handleEventClick(event)}
                >
                  {event.images?.length > 0 && (
                    <div className="relative aspect-video bg-gray-100">
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/uploads/${event.images[0]}`}
                        alt={event.name}
                        className="w-full h-full object-cover"
                      />
                      {event.images.length > 1 && (
                        <div className="absolute bottom-2 right-2 bg-black bg-opacity-70 text-white px-2 py-1 rounded-lg text-sm">
                          +{event.images.length - 1}
                        </div>
                      )}
                    </div>
                  )}
                  
                  <div className="p-4">
                    <h2 className="text-lg font-semibold mb-2 truncate">{event.name}</h2>
                    <p className="text-gray-600 text-sm">
                      {new Date(event.date).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Timeline (Always on right) */}
        <div className="w-24 flex-shrink-0">
          <div className="relative flex items-center h-full">
            {/* Vertical Line */}
            <div className="absolute left-2 top-0 bottom-0 w-0.5 bg-gray-300" />

            {/* Dots and Years */}
            <div className="flex flex-col items-start space-y-12">
              {years.map((year) => (
                <button
                  key={year}
                  onClick={() => setSelectedYear(parseInt(year))}
                  className="group relative flex items-center"
                >
                  {/* Dot */}
                  <div
                    className={`w-4 h-4 rounded-full z-10 transition-colors
                      ${selectedYear === parseInt(year)
                        ? 'bg-orange-500'
                        : 'bg-gray-300 group-hover:bg-gray-400'}`}
                  />

                  {/* Year text */}
                  <span
                    className={`ml-4 whitespace-nowrap transition-colors
                      ${selectedYear === parseInt(year)
                        ? 'text-orange-500 font-bold'
                        : 'text-gray-500 group-hover:text-gray-700'}`}
                  >
                    {year}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryDisplay;