
import React from "react";

const TamilSchoolDetails = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-lg w-full">
        <h1 className="text-3xl font-bold mb-4 text-center text-indigo-600">
          தமிழ் பள்ளியின் விவரங்கள்
        </h1>
        <div className="text-gray-700 mb-6">
          <p className="mb-4">
            <strong>பள்ளியின் பெயர்:</strong> அரசு உயர்நிலைப் பள்ளி, சினகலந்துபுரம்
          </p>
          <p className="mb-4">
            <strong>இடம்:</strong> சினகலந்துபுரம், நாமக்கல் மாவட்டம், தமிழ்நாடு
          </p>
          <p className="mb-4">
            <strong>தொடர்புக்கு:</strong> +91-9876543210
          </p>
          <p className="mb-4">
            <strong>மின்னஞ்சல்:</strong> tamilschool@example.com
          </p>
          <p className="mb-4">
            <strong>பள்ளியின் சிறப்பம்சங்கள்:</strong>
          </p>
          <ul className="list-disc ml-6">
            <li>அறிவியல் மற்றும் கணிதத்தில் சிறப்பு கவனம்</li>
            <li>இலவச midday உணவுத் திட்டம்</li>
            <li>தமிழ் இலக்கிய வகுப்புகள் மற்றும் கலாச்சார நிகழ்வுகள்</li>
            <li>பாடசாலை பராமரிப்பு மற்றும் முறையான விளையாட்டு மைதானம்</li>
          </ul>
        </div>
        <div className="text-center">
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded">
            மேலும் தகவல்
          </button>
        </div>
      </div>
    </div>
  );
};

export default TamilSchoolDetails;
