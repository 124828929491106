import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// User ID Validation and Generation Utilities
const validateUserId = (userId) => {
  const validationRegex = /^(U-\d+(-[a-z0-9]+)?|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/;
  return validationRegex.test(userId);
};

const generateUserId = () => {
  return `U-${Date.now()}-${Math.random().toString(36).substring(2, 10)}`;
};

// Thank You Popup Component
const ThankYouPopup = ({ onClose, membershipType }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-xl text-center max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4 text-green-600">Thank You!</h2>
        <p className="mb-6 text-gray-700">
          Welcome to the {membershipType} Family! 
          We're excited to have you on board.
        </p>
        <button 
          onClick={onClose}
          className="bg-orange-500 text-white py-2 px-6 rounded-md hover:bg-orange-600 transition"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

// Submission Blocked Popup Component
const SubmissionBlockedPopup = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-xl text-center max-w-md w-full">
        <div className="mb-4 text-red-600">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-16 w-16 mx-auto" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
            />
          </svg>
        </div>
        <h2 className="text-2xl font-bold mb-4 text-red-600">Submission Not Allowed</h2>
        <p className="mb-6 text-gray-700">
          A membership request has already been submitted with this email address.
        </p>
        <button 
          onClick={onClose}
          className="bg-red-500 text-white py-2 px-6 rounded-md hover:bg-red-600 transition"
        >
          Understood
        </button>
      </div>
    </div>
  );
};



// Main Billing Form Component
const BillingForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);
  const [submissionBlocked, setSubmissionBlocked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [userId, setUserId] = useState('');

  // Initialize formData with empty values
  const [formData, setFormData] = useState({
    userId: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    from: '',
    firstName: '',
    lastName: '',
    phoneCountry: '+91',
    phoneNumber: '',
    addressLine1: '',
    suburb: '',
    stateProvince: '',
    postalCode: '',
    profession: '',
    dateOfBirth: '',
    gender: '',
    referredBy: '',
    maritalStatus: ''
  });

  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [passport, setPassport] = useState(null);
  const [supportingDocuments, setSupportingDocuments] = useState([]);

  // Function to get user email from localStorage
 const getUserEmail = () => {
  const userValue = localStorage.getItem('user');
  if (userValue) {
    try {
      const userData = JSON.parse(userValue);
      // Access email from the nested value object
      return userData.value?.email || '';
    } catch (error) {
      console.error('Error parsing user data:', error);
      return '';
    }
  }
  return '';
};

 useEffect(() => {
  const checkLoginStatus = () => {
    const userValue = localStorage.getItem('user');
    const generatedUserId = generateUserId();
    setUserId(generatedUserId);

    if (userValue) {
      try {
        const userData = JSON.parse(userValue);
        // Access data from the nested value object
        const userDetails = userData.value;
        setIsLoggedIn(true);
        
        setFormData(prevData => ({
          ...prevData,
          userId: generatedUserId,
          name: userDetails.name || '',
          email: userDetails.email || '',
          firstName: userDetails.firstName || '',
          lastName: userDetails.lastName || '',
          phoneNumber: userDetails.phone || '',
        }));
      } catch (error) {
        console.error('Error parsing user data:', error);
      }
    } else {
      setFormData(prevData => ({
        ...prevData,
        userId: generatedUserId
      }));
    }
  };

  checkLoginStatus();

  if (location.state && location.state.selectedMembership) {
    setSelectedMembership(location.state.selectedMembership);
  }
}, [location]);


  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    
    if (name === 'passport') {
      setPassport(files[0]);
      console.log('Passport updated:', files[0]);
      return;
    }

    if (name === 'supportingDocuments') {
      setSupportingDocuments(Array.from(files));
      console.log('Supporting Documents updated:', Array.from(files));
      return;
    }

    setFormData(prevData => {
      const updatedData = { ...prevData, [name]: value };
      console.log('FormData updated:', updatedData);
      return updatedData;
    });
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  
  // Step 1: Check if this email has already submitted a membership
  const submittedEmails = JSON.parse(localStorage.getItem('submittedMembershipEmails') || '[]');
  
  if (submittedEmails.includes(formData.email)) {
    setSubmissionBlocked(true);
    setError('A membership request has already been submitted with this email.');
    
    // Auto-hide error after 5 seconds
    setTimeout(() => {
      setSubmissionBlocked(false);
      setError('');
    }, 5000);
    
    return; // Exit the function if the email is already submitted
  }

  // Step 2: Validation checks

  // Check if user is logged in
  if (!isLoggedIn) {
    console.error('Login Error: User not logged in');
    setError('Please log in before proceeding');
    return;
  }
  
  // Check if terms are agreed
  if (!agreedToTerms) {
    console.error('Terms Error: Terms not agreed');
    setError('Please agree to the terms and conditions');
    return;
  }

  // Step 3: Comprehensive form validation
  const requiredFields = [
    'firstName', 'lastName', 'email', 'phoneNumber', 
    'addressLine1', 'suburb', 'stateProvince', 'postalCode', 
    'profession', 'dateOfBirth', 'gender', 'maritalStatus'
  ];

  const missingFields = requiredFields.filter(field => !formData[field]);
  
  if (missingFields.length > 0) {
    console.error('Validation Error: Missing fields', missingFields);
    setError(`Please fill in all required fields: ${missingFields.join(', ')}`);
    return; // Exit the function if required fields are missing
  }

  // Step 4: Prepare the data for submission

  // Prepare full phone number
  const fullPhone = `${formData.phoneCountry}${formData.phoneNumber}`;

  // Prepare the membership data object to match backend expectations
  const membershipData = {
    userId: userId,
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    phone: fullPhone,
    address: `${formData.addressLine1}, ${formData.suburb}, ${formData.stateProvince}, ${formData.postalCode}`,
    username: formData.email.split('@')[0], // Extract username from email
    profession: formData.profession,
    dateOfBirth: formData.dateOfBirth,
    gender: formData.gender,
    referredBy: formData.referredBy || '',
    maritalStatus: formData.maritalStatus,
    from: formData.profession,
    membershipName: selectedMembership.name,
    passport: passport ? passport.name : null,
    supportingDocuments: supportingDocuments.map(doc => doc.name)
  };

  // Step 5: Start loading state
  setLoading(true);
  setError(''); // Clear any existing error

  try {
    console.log('Submitting membership data:', membershipData);

    // Make the POST request to the backend to submit the membership data
    const response = await fetch('http://atabackend.quindltechnologies.com:3003/membership/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(membershipData),
    });

    // Check if the response is successful
    if (!response.ok) {
      const errorText = await response.text();
      console.error('Submission Error:', errorText);
      throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
    }

    // Parse the successful response data
    const responseData = await response.json();
    console.log('Submission successful:', responseData);

    // Step 6: Store the email to prevent future submissions
    const updatedSubmittedEmails = [...submittedEmails, formData.email];
    localStorage.setItem('submittedMembershipEmails', JSON.stringify(updatedSubmittedEmails));

    // Show thank you popup (or any other UI feedback)
    setShowThankYouPopup(true);

    // Optional: Store the membership request ID (could be used later)
    localStorage.setItem('membershipRequestId', responseData.requestId);

  } catch (err) {
    console.error('Full submission error:', err);
    setError(err.message || 'Failed to submit membership request');
  } finally {
    // Ensure the loading state is always turned off
    setLoading(false);
  }
  };
  
  
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleTermsClick = (e) => {
    e.preventDefault();
    // Navigate in same window
    window.location.href = '/terms&conditions';
  };


  const handlePopupClose = () => {
    setShowThankYouPopup(false);

    // Ensure navigation happens only for Silver-Family and Gold-Family
    const allowedMemberships = ['Silver-Family', 'Gold-Family'];
    if (allowedMemberships.includes(selectedMembership?.name)) {
      navigate('/confirmation', {
        state: {
          membershipType: selectedMembership?.name,
          userId: userId
        }
      });
    } else {
      navigate('/')
    }
  };

  const handleLogin = () => {
    navigate('/login', { 
      state: { 
        from: location.pathname,
        membership: selectedMembership 
      } 
    });
  };
  return (
    <>
      {showThankYouPopup && (
        <ThankYouPopup 
          onClose={handlePopupClose} 
          membershipType={selectedMembership?.name || 'Silver/Gold'}
        />
      )}

      {submissionBlocked && (
        <SubmissionBlockedPopup 
          onClose={() => setSubmissionBlocked(false)} 
        />
      )}

      <form onSubmit={handleSubmit} className="bg-gradient-to-br from-purple-100 via-pink-50 to-blue-50 p-12">
        <div className="max-w-6xl mx-auto">
          <h1 className="text-2xl font-bold mb-8 text-gray-800">Billing details</h1>
          
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
              {error}
            </div>
          )}

          <div className="lg:col-span-2 p-6">
            <div className="bg-white rounded-lg shadow-lg p-12 space-y-6">
              {/* Personal Information Fields */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    First Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Last Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              {/* Contact Information */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email-ID <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  className="w-full p-2 border border-gray-300 rounded-md bg-gray-100"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled
                  required
                />
                <p className="text-xs text-gray-500 mt-1">
                </p>
              </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Phone <span className="text-red-500">*</span>
                  </label>
                  <div className="flex">
                    <select
                      name="phoneCountry"
                      className="w-24 p-2 border border-gray-300 rounded-l-md"
                      value={formData.phoneCountry}
                      onChange={handleInputChange}
                    >
                      <option value="+91">+91</option>
                      <option value="+1">+1</option>
                      <option value="+44">+44</option>
                    </select>
                    <input
                      type="tel"
                      name="phoneNumber"
                      className="flex-1 p-2 border border-gray-300 rounded-r-md"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Address */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Address line 1 <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="addressLine1"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={formData.addressLine1}
                  onChange={handleInputChange}
                  required
                />
              </div>

              {/* Location Details */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Suburb <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="suburb"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={formData.suburb}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    State/Province <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="stateProvince"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={formData.stateProvince}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Postal Code <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="postalCode"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={formData.postalCode}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              {/* Account Details */}
          <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Account username <span className="text-red-500">*</span>
        </label>
        <div className="w-full p-2 border border-gray-300 rounded-md bg-gray-100">
          <span className="text-gray-600">
            {formData.email ? formData.email.split('@')[0] : ''}
          </span>
        </div>
      </div>


      

              {/* Additional Information */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Profession <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="profession"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={formData.profession}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Date of Birth <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    name="dateOfBirth"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={formData.dateOfBirth}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Gender <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="gender"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={formData.gender}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select your gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Referred by (optional)
                  </label>
                  <input
                    type="text"
                    name="referredBy"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={formData.referredBy}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Marital Status <span className="text-red-500">*</span>
                </label>
                <select
                  name="maritalStatus"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={formData.maritalStatus}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select your Marital status</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                </select>
              </div>
               
            </div>
          </div>


          {/* Order Summary Section */}

     <div className='flex justify-center mt-6'>
            <div className="max-w-2xl lg:col-span-1">
              <div className="bg-white rounded-lg shadow-lg">
                <div className="bg-gradient-to-r from-orange-500 to-red-600 p-4 rounded-t-lg">
                  <h2 className="text-xl font-bold text-white">Your Order</h2>
                </div>
                
                <div className="p-6 space-y-6">
                  {selectedMembership && (
                    <div className="flex justify-between items-center">
                      <span className="font-medium">{selectedMembership.name} x 1</span>
                      <span>{selectedMembership.price}</span>
                    </div>
                  )}

                  <div className="border-t pt-4">
                    <div className="flex justify-between items-center font-bold">
                      <span>TOTAL</span>
                      <span>{selectedMembership ? selectedMembership.price : '$ 0.00'}</span>
                    </div>
                  </div>

                  <div className="border-t pt-4">
                    <p className="text-sm text-gray-600 mb-4">
                      By purchasing the Membership / Event Tickets, you are agreed to ATA privacy policy.
                    </p>
                   <div className="flex items-start gap-2 max-w-lg">
                      <input
                        type="checkbox"
                        id="terms"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="mt-1"
                      />
                      <label htmlFor="terms" className="text-sm text-gray-600">
                        I have read and agree to the website{' '}
                        <a
                          href="/terms&conditions"
                          onClick={handleTermsClick}
                          className="text-blue-600 hover:text-blue-800 underline"
                        >
                          terms and conditions
                        </a>
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                    </div>
                  </div>

                  <button 
                    type="submit"
                    disabled={loading}
                    className="w-full bg-orange-500 text-white py-3 px-4 rounded-md font-bold hover:bg-orange-600 transition duration-200"
                  >
                    {loading ? 'SUBMITTING...' : 'PAY NOW'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default BillingForm;