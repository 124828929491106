import React, { useState, useEffect } from 'react';
import './post.css';

const LatestPostsSection = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLatestPosts = async () => {
      try {
        const response = await fetch('http://atabackend.quindltechnologies.com:3003/blog/get');
        const allPosts = await response.json();
        
        // Sort posts by date and get latest 3
        const latestPosts = allPosts
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 3);
          
        setPosts(latestPosts);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchLatestPosts();
  }, []);

  if (loading) {
    return (
      <div className="w-full py-12 px-4 bg-[#F8F8FA] text-center">
        <div className="max-w-4xl mx-auto">
          <div className="animate-pulse text-gray-500 font-semibold">Loading latest posts...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full py-16 px-4 bg-[#F8F8FA] text-center">
        <div className="max-w-7xl mx-auto">
          <div className="text-red-500">Error loading posts</div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full py-16 px-4 bg-[#F8F8FA]">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-[#E16811] mb-4">
            Our Latest Post
          </h2>
          <p className="text-gray-600 text-lg">
            All the Newsletter / Post published by <br /> Adelaide Tamil Association
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center">
          {posts.map((post) => (
            <div
              key={post._id}
              className="w-full max-w-[400px] bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:scale-105 hover:shadow-xl hover-orange-shadow"
            >
              <div className="aspect-w-16 aspect-h-9 overflow-hidden relative bg-gradient-to-br from-gray-50 to-gray-100">
                <img 
                  src={`http://atabackend.quindltechnologies.com:3003/uploads/${post.image}`}
                  alt={post.name} 
                  className="w-full h-[300px] object-cover"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/assets/post-placeholder.svg';
                  }}
                />
              </div>
              <div className="p-6 text-center">
                <h3 className="text-xl font-semibold text-gray-800 mb-4 line-clamp-2 min-h-[3.5rem]">
                  {post.name}
                </h3>
                <div className="mt-4">
                  <button
                    className="w-full sm:w-auto px-8 py-3 text-[#E16811] border-2 border-[#E16811] rounded-full hover:bg-[#E16811] hover:text-white transition-all duration-300 font-medium"
                    onClick={() => window.location.href = `/blog/${post._id}`}
                  >
                    Read More
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestPostsSection;