import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight, X, User, Mail, Phone, MapPin, Crown, Briefcase, Calendar } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { format } from 'date-fns';

// Separate MembershipDisplay component
const MembershipDisplay = ({ userMembership }) => {
  const InfoItem = ({ icon: Icon, label, value }) => (
    <div className="flex flex-col sm:flex-row sm:items-center p-4 rounded-lg hover:bg-gray-50 transition-colors">
      <div className="flex items-center mb-2 sm:mb-0 sm:w-48 sm:flex-shrink-0">
        <Icon className="w-4 h-4 text-gray-400 mr-2" />
        <span className="text-sm font-medium text-gray-500">{label}</span>
      </div>
      <span className="text-gray-900 ml-6 sm:ml-0">{value}</span>
    </div>
  );

  return (
    <div className="w-full max-w-5xl mx-auto mb-12">
      <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-indigo-50"></div>
          <div className="relative p-6 sm:p-8 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <div>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900">
                {userMembership.status === 'Pending' ? 'Pending' : 'Current'} Membership
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Member since {userMembership.createdAt ? format(new Date(userMembership.createdAt), 'MMMM yyyy') : 'N/A'}
              </p>
            </div>
            <div className={`px-4 py-2 rounded-full text-sm font-medium 
              ${userMembership.status === 'Active' ? 'bg-green-100 text-green-800' :
                userMembership.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' :
                'bg-gray-100 text-gray-800'}`}>
              {userMembership.status}
            </div>
          </div>
        </div>

        <div className="h-px bg-gray-100"></div>

        <div className="p-6 sm:p-8 space-y-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="space-y-1">
              <InfoItem 
                icon={User}
                label="Full Name"
                value={`${userMembership.firstname} ${userMembership.lastname}`}
              />
              <InfoItem 
                icon={Mail}
                label="Email"
                value={userMembership.email}
              />
              <InfoItem 
                icon={Phone}
                label="Phone"
                value={userMembership.phone}
              />
              <InfoItem 
                icon={MapPin}
                label="Address"
                value={userMembership.address}
              />
            </div>
            
            <div className="space-y-1">
              <InfoItem 
                icon={Crown}
                label="Membership Type"
                value={userMembership.membershipName}
              />
              <InfoItem 
                icon={Briefcase}
                label="Profession"
                value={userMembership.profession}
              />
              <InfoItem 
                icon={Calendar}
                label="Submission Date"
                value={userMembership.createdAt ? format(new Date(userMembership.createdAt), 'PPP') : 'N/A'}
              />
            </div>
          </div>

          {userMembership.status === 'Pending' && (
            <div className="mt-6 animate-pulse">
              <div className="bg-yellow-50 border border-yellow-100 rounded-xl p-4 sm:p-6">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">Under Review</h3>
                    <p className="mt-1 text-sm text-yellow-700">
                      Your membership request is currently under review. We'll notify you once it's approved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MembershipProgram = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [userMembership, setUserMembership] = useState(null);
  const [loading, setLoading] = useState(true);
  const [displayedMembershipLevels, setDisplayedMembershipLevels] = useState([]);
  const descriptionRef = useRef(null);

  const membershipLevels = [
    {
      name: 'Silver-Single',
      type: 'Annual Membership',
      price: '$10.00',
      gradientClasses: 'from-blue-300 via-purple-300 to-purple-400',
      isVisible: true,
      benefits: [
        '25% discount on event tickets',
        'Access to networking events',
        'Monthly newsletter subscription',
        'Basic member directory listing'
      ]
    },
    {
      name: 'Silver-Family',
      type: 'Annual Membership',
      price: '$20.00',
      gradientClasses: 'from-orange-400 via-orange-500 to-red-500',
      isVisible: true,
      benefits: [
        'All Silver-Single benefits',
        'Family access to events',
        'Quarterly family gatherings',
        'Family member directory listings'
      ]
    },
    {
      name: 'Gold-Student',
      type: 'Life-time Membership',
      price: '$50.00',
      gradientClasses: 'from-blue-700 via-blue-500 to-blue-600',
      isVisible: true,
      benefits: [
        'All Silver benefits',
        'Career mentorship program',
        'Student workshops access',
        'Resume review services'
      ]
    },
    {
      name: 'Gold-Single',
      type: 'Life-time Membership',
      price: '$100.00',
      gradientClasses: 'from-green-400 via-green-500 to-green-600',
      isVisible: true,
      benefits: [
        'All Gold-Student benefits',
        'Priority event registration',
        'Exclusive networking sessions',
        'Professional development workshops'
      ]
    },
    {
      name: 'Gold-Family',
      type: 'Life-time Membership',
      price: '$200.00',
      gradientClasses: 'from-purple-400 via-purple-500 to-purple-600',
      isVisible: true,
      benefits: [
        'All Gold-Single benefits',
        'Family-wide lifetime access',
        'Premium event seating',
        'Special family recognition'
      ]
    }
  ];

  const executiveBenefits = [
    {
      image: "/assets/member-1.svg",
      title: "Navigating the Future in AI",
      date: "April 28th, 2024"
    },
    {
      image: "/assets/member-2.svg",
      title: "Professional Networking Event",
      date: "May 15th, 2024"
    },
    {
      image: "/assets/member-3.svg",
      title: "Leadership Development Workshop",
      date: "June 5th, 2024"
    },
    {
      image: "/assets/member-4.svg",
      title: "Annual Business Conference",
      date: "July 20th, 2024"
    },
    {
      image: "/assets/member-5.svg",
      title: "Innovation Summit",
      date: "August 10th, 2024"
    },
    {
      image: "/assets/member-6.svg",
      title: "Executive Masterclass",
      date: "September 15th, 2024"
    }
  ];

  const getAvailableMemberships = (currentMembershipName) => {
    if (!currentMembershipName) {
      return membershipLevels;
    }
    return membershipLevels.filter(level => 
      level.name !== currentMembershipName
    );
  };

  const scrollToDescription = () => {
    descriptionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchAndFilterMemberships = async () => {
      try {
        setLoading(true);

        const userString = localStorage.getItem("user");
        if (!userString) {
          setDisplayedMembershipLevels(membershipLevels.filter(level => level.isVisible));
          setLoading(false);
          return;
        }

        const userData = JSON.parse(userString);
        const email = userData?.value?.email?.trim();

        if (!email) {
          setDisplayedMembershipLevels(membershipLevels.filter(level => level.isVisible));
          setLoading(false);
          return;
        }

        const response = await fetch("http://atabackend.quindltechnologies.com:3003/membership/get", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ email: email })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        
        if (data.membership) {
          setUserMembership(data.membership);
          
          if (data.membership.status === 'Active') {
            const availableMemberships = getAvailableMemberships(data.membership.membershipName);
            setDisplayedMembershipLevels(availableMemberships);
          } else {
            setDisplayedMembershipLevels(membershipLevels.filter(level => level.isVisible));
          }
        } else {
          setDisplayedMembershipLevels(membershipLevels.filter(level => level.isVisible));
        }

      } catch (error) {
        console.error("Error fetching membership:", error);
        setDisplayedMembershipLevels(membershipLevels.filter(level => level.isVisible));
      } finally {
        setLoading(false);
      }
    };

    fetchAndFilterMemberships();
  }, []);

  const checkLoginAndProceed = (membership) => {
    try {
      const userString = localStorage.getItem("user");
      if (!userString) {
        showLoginMessage();
        return;
      }

      const userData = JSON.parse(userString);
      if (!userData?.value?.email) {
        showLoginMessage();
        return;
      }

      if (userMembership) {
        const currentType = userMembership.membershipName;
        const newType = membership.name;
        
        if (currentType === newType) {
          setShowLoginAlert(true);
          setTimeout(() => setShowLoginAlert(false), 3000);
          return;
        }
      }
      
      setSelectedMembership(membership);
      setShowDetails(true);
    } catch (error) {
      console.error("Error checking login status:", error);
      showLoginMessage();
    }
  };

  const showLoginMessage = () => {
    setShowLoginAlert(true);
    setTimeout(() => {
      setShowLoginAlert(false);
      navigate("/login");
    }, 2000);
  };

  const handleProceedToCheckout = () => {
    navigate("/forms", { 
      state: { selectedMembership: selectedMembership }
    });
  };

  const nextSlide = () => {
    setCurrentSlide((prev) =>
      prev === executiveBenefits.length - 1 ? 0 : prev + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? executiveBenefits.length - 1 : prev - 1
    );
  };

  const handleModalClick = (e) => {
    if (e.target === e.currentTarget) {
      setShowDetails(false);
    }
  };

  const renderMembershipDetails = () => {
    if (!userMembership) return null;
    return <MembershipDisplay userMembership={userMembership} />;
  };

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="w-full relative">
      {showLoginAlert && (
        <div className="fixed top-4 right-4 z-50 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          <p>{userMembership ? "You already have an active membership" : "Please log in to proceed with the purchase."}</p>
        </div>
      )}

      <div className='bg-gradient-to-br from-purple-100 via-pink-50 to-blue-50 py-6'>
        <div className="w-full mb-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 md:flex md:items-center md:justify-between">
            <div className="md:w-1/2 space-y-6 mb-8 md:mb-0 flex flex-col items-center md:items-start">
              <div className="flex items-center gap-4 text-center md:text-left">
                <div className="text-orange-500 text-2xl">✦</div>
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-800 font-montserrat text-center md:text-left">
                  ATA'S NEW <br />MEMBERSHIP PROGRAM
                </h1>
              </div>
              <div className="flex justify-center w-full md:justify-start mt-6 mb-12">
                <button 
                  className="bg-orange-500 text-white flex align-center px-8 py-2 rounded-md hover:bg-orange-600 transition-colors ml-10 mb-12 "
                  onClick={scrollToDescription}
                >
                  Learn More
                </button>
              </div>
            </div>
            <div className="md:w-1/2 flex justify-center">
              <img 
                src="/assets/member-main.svg"
                alt="Business meeting"
                className="w-full max-w-xl h-auto rounded-lg object-cover shadow-lg -mt-12"
              />
            </div>
          </div>
        </div>

        <div ref={descriptionRef} className="mx-auto px-4 sm:px-6 lg:px-8 space-y-12">
          <div className="space-y-4 mb-32 text-start">
            <h2 className="text-4xl font-bold text-gray-800 text-center mb-16">
              What is the ATA'S Membership Program?
            </h2>
            <p className="text-gray-600 text-xl md:text-2xl px-7 mx-auto">
              The Adelaide Tamil Association (ATA) is proud to announce the newly revamped Membership program.
            </p>
            <p className="text-gray-600 text-xl md:text-2xl px-7 mx-auto">
              Members will save over $150 on various professional development & networking events. 
              Our goal is to help professionals get the support they need to accelerate their success. 
              All proceeds will be donated to the Tamil Community Centre initiative.
            </p>
          </div>

          <div className="w-full p-8">
            <h2 className="text-3xl font-bold mb-8 text-gray-800 text-center">Membership Level</h2>
            
            {renderMembershipDetails()}
            
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 justify-center">
              {displayedMembershipLevels.map((level, index) => (
                <div key={index} className="relative group w-full max-w-[250px] mx-auto">
                  <div className="relative h-80 w-full bg-white rounded-3xl overflow-hidden shadow-lg">
                    <div className={`absolute left-0 top-0 bottom-0 w-8 bg-gradient-to-b ${level.gradientClasses} rounded-l-lg`} />
                    <div className={`absolute left-0 right-0 bottom-0 h-12 bg-gradient-to-r ${level.gradientClasses}`} />
                    <div className={`absolute left-0 top-0 w-52 rounded-3xl h-3 bg-gradient-to-r ${level.gradientClasses}`} />
                    <div className="h-full p-6 flex flex-col">
                      <div className="absolute top-8 right-0 w-16 h-4 bg-gray-800 rounded-full" />
                      
                      <div className="space-y-3 mt-8 ml-10">
                        <h3 className="text-xl font-bold text-gray-800">{level.name}</h3>
                        <p className="text-gray-600 text-sm">{level.type}</p>
                      </div>
                      
                      <div className="mt-auto mb-6">
                        <div className="relative bg-gray-800 text-white rounded-full p-4 w-24 h-24 mx-auto flex flex-col items-center justify-center">
                          <p className="text-lg font-bold mb-1">{level.price}</p>
                          <button
                            className="text-sm hover:underline transition-colors text-white"
                            onClick={() => checkLoginAndProceed(level)}
                          >
                            Buy now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full max-w-7xl mx-auto px-8 py-12">
        <div className="flex flex-col md:flex-row gap-12 items-center">
          <div className="w-full md:w-1/2 space-y-4 text-center md:text-left">
            <h2 className="text-5xl font-bold text-gray-800">Membership Exclusive</h2>
            <p className="text-2xl text-gray-600 max-w-prose mx-auto md:mx-0">
              Members will receive 25% off tickets to Toast to Success, Early Bird Ticket Access 
              for Black & White Affair, and free entry to professional development workshops!
            </p>
          </div>
          
          <div className="relative w-full md:w-1/2 max-w-md mx-auto">
            <div className="overflow-hidden rounded-2xl">
              <div
                className="flex transition-transform duration-500 ease-in-out"
                style={{ transform: `translateX(-${currentSlide * 100}%)` }}
              >
                {executiveBenefits.map((benefit, index) => (
                  <div key={index} className="w-full flex-shrink-0 text-center bg-white">
                    <img
                      src={benefit.image}
                      alt={benefit.title}
                      className="w-full h-64 object-cover"
                    />
                    <div className="p-4">
                      <h3 className="text-lg font-semibold">{benefit.title}</h3>
                      <p className="text-gray-500">{benefit.date}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <button
              onClick={prevSlide}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 p-2 bg-white rounded-full shadow-md hover:bg-gray-100"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <button
              onClick={nextSlide}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 bg-white rounded-full shadow-md hover:bg-gray-100"
            >
              <ChevronRight className="w-6 h-6" />
            </button>

            <div className="flex justify-center mt-4">
              {executiveBenefits.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                  className={`h-2 w-2 mx-1 rounded-full ${
                    currentSlide === index ? "bg-blue-600" : "bg-gray-300 hover:bg-gray-500"
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {showDetails && selectedMembership && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleModalClick}
        >
          <div className="bg-white p-8 rounded-md w-11/12 md:w-1/2 relative">
            <button 
              onClick={() => setShowDetails(false)} 
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              <X />
            </button>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">{selectedMembership.name}</h2>
            <ul className="space-y-2 mb-6">
              {selectedMembership.benefits.map((benefit, index) => (
                <li key={index} className="text-gray-600">
                  - {benefit}
                </li>
              ))}
            </ul>
            <p className="text-lg font-semibold mb-4">Price: {selectedMembership.price}</p>
            <div className="flex justify-end space-x-4">
              <button 
                className="px-6 py-2 rounded-md text-gray-700 hover:bg-gray-100"
                onClick={() => setShowDetails(false)}
              >
                Cancel
              </button>
              <button 
                className="px-6 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
                onClick={handleProceedToCheckout}
              >
                Proceed to Checkout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MembershipProgram;